import classes from "./LandingPage.module.css";

const LandingPage = () => {
  return (
    <section id="home" className={classes["landing-page"]}>
      <div className={classes.content}>
        <h1 className={classes.title}>Valot Ads Agency</h1>
        <h1 className={classes.greeting}>
          Welcome to our marketing and advertising agency website
        </h1>
        <p>
          where we specialize in helping businesses of all sizes achieve their
          marketing goals. Our team of experienced professionals is dedicated to
          providing top-notch services that are tailored to meet your specific
          needed. From branding and creative design to digital marketing and
          social media management, we have the expertise to take your business
          to the next level
        </p>
        <a href="#services">Learn more</a>
      </div>
      <div className={classes.image}>
        <img src={require("../assets/laptop.png")} alt="Laptop" />
      </div>
    </section>
  );
};

export default LandingPage;
