import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Contacts.module.css";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import { memo } from "react";

const Contacts = () => {
  return (
    <div className={classes.contacts}>
      <h3 className="flex-nowrap">Contact us:</h3>
      <div className={classes.contact}>
        <FontAwesomeIcon className={classes.icon} icon={faPhone} />
        <span className="flex-nowrap">+20 100 7089 579</span>
      </div>
      <div className={classes.contact}>
        <FontAwesomeIcon className={classes.icon} icon={faEnvelope} />
        <span className="flex-nowrap">valotads@gmail.com</span>
      </div>
    </div>
  );
};

export default memo(Contacts);
