import classes from "./Clients.module.css";
import { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import MainTitle from "./UI/MainTitle";

const clients = [
  {
    src: require("../assets/clients/ALHODHOD.png"),
    alt: "Alhodhod Company",
  },
  // {
  //   src: require("../assets/clients/BETA ACADEMY.png"),
  //   alt: "Beta Academy",
  // },
  // {
  //   src: require("../assets/clients/CARAMEL.png"),
  //   alt: "Caramel Company",
  // },
  {
    src: require("../assets/clients/FOX CHEM.png"),
    alt: "Fox Chem Company",
  },
  {
    src: require("../assets/clients/NUMBER ONE.jpg"),
    alt: "Number One Company",
  },
];

const Clients = () => {
  const swiperRef = useRef();

  const swipeHandler = (direction = true) => {
    swiperRef.current.scrollBy({
      top: 0,
      left: direction
        ? swiperRef.current.offsetWidth
        : -swiperRef.current.offsetWidth,
      behavior: "smooth",
    });
  };

  return (
    <section id="clients">
      <MainTitle>Clients</MainTitle>
      <div className={classes.card}>
        <button onClick={() => swipeHandler(false)} className={classes.arrow}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
        <div className={classes.swiper} ref={swiperRef}>
          {clients.map((client, index) => (
            <div key={index} className={classes.client}>
              <img src={client.src} alt={client.alt} />
            </div>
          ))}
        </div>
        <button onClick={() => swipeHandler()} className={classes.arrow}>
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      </div>
    </section>
  );
};

export default Clients;
