import MainTitle from "./UI/MainTitle";
import classes from "./Portfolio.module.css";
import { useState, useCallback, memo } from "react";
import Navigator from "./Navigator";

const portfolio = [
  {
    name: "Aldoha One",
    images: [
      require("../assets/portfolio/Aldoha One/aldoha one adv insta1.jpg"),
      require("../assets/portfolio/Aldoha One/aldoha one adv insta2.jpg"),
      require("../assets/portfolio/Aldoha One/aldoha one adv insta3.jpg"),
      require("../assets/portfolio/Aldoha One/aldoha one adv insta4.jpg"),
      require("../assets/portfolio/Aldoha One/aldoha one adv insta5.jpg"),
    ],
  },
  {
    name: "Leader",
    images: [
      require("../assets/portfolio/Leader/1.jpg"),
      require("../assets/portfolio/Leader/2.jpg"),
      require("../assets/portfolio/Leader/3.jpg"),
      require("../assets/portfolio/Leader/4.jpg"),
    ],
  },
  {
    name: "Lolita",
    images: [
      require("../assets/portfolio/Lolita/Lolita Resin Art1.jpg"),
      require("../assets/portfolio/Lolita/Lolita Resin Art2.jpg"),
      require("../assets/portfolio/Lolita/Lolita Resin Art3.jpg"),
      require("../assets/portfolio/Lolita/Lolita Resin Art4.jpg"),
      require("../assets/portfolio/Lolita/Lolita Resin Art5.jpg"),
    ],
  },
  {
    name: "Natural Eye",
    images: [
      require("../assets/portfolio/Natural Eye/Nature Eye Insta1.jpg"),
      require("../assets/portfolio/Natural Eye/Nature Eye Insta2.jpg"),
      require("../assets/portfolio/Natural Eye/Nature Eye Insta3.jpg"),
      require("../assets/portfolio/Natural Eye/Nature Eye Insta4.jpg"),
      require("../assets/portfolio/Natural Eye/Nature Eye Insta5.jpg"),
      require("../assets/portfolio/Natural Eye/Nature Eye Insta6.jpg"),
    ],
  },
  {
    name: "Number One",
    images: [
      require("../assets/portfolio/Number One/N1 Insta 1.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 2.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 3.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 4.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 5.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 6.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 7.jpg"),
      require("../assets/portfolio/Number One/N1 Insta 8.jpg"),
    ],
  },
  {
    name: "RS",
    images: [
      require("../assets/portfolio/RS/RS.Line.sa Social Media-1.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-2.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-3.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-4.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-5.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-6.jpg"),
      require("../assets/portfolio/RS/RS.Line.sa Social Media-7.jpg"),
    ],
  },
];

const Portfolio = () => {
  const [activeWork, setActiveWork] = useState(null);
  const closeNavigatorHandler = useCallback(() => setActiveWork(null), []);

  return (
    <section id="portfolio" className={classes.portfolio}>
      <MainTitle>Portfolio</MainTitle>
      {activeWork && (
        <Navigator onClick={closeNavigatorHandler} work={activeWork} />
      )}
      <div>
        {portfolio.map((p, index) => (
          <div
            key={index}
            className={classes.image}
            onClick={() =>
              setActiveWork({
                ...p,
                images: p.images.map((i) => {
                  return { src: i, id: Math.random().toString() };
                }),
              })
            }
          >
            <img src={p.images[0]} alt={p.name} />
          </div>
        ))}
      </div>
    </section>
  );
};

export default memo(Portfolio);
