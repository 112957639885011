import { useState, memo } from "react";
import Backdrop from "./UI/Backdrop";
import classes from "./Navigator.module.css";
import { useEffect } from "react";

const Navigator = ({ work, onClick }) => {
  const [activeImage, setActiveImage] = useState(work.images[0]);
  const [zoomedIn, setZoomedIn] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const resizeWindowHandler = () => setScreenSize(window.innerWidth);
    const arrowNavigateHandler = (e) => {
      if (e.keyCode === 37) {
        // Left arrow
        work.images.find((image, index) => {
          if (image.id === activeImage.id && index !== 0) {
            setActiveImage(work.images[index - 1]);
          }
          return image.id === activeImage.id;
        });
      } else if (e.keyCode === 39) {
        // Right arrow
        work.images.find((image, index) => {
          if (image.id === activeImage.id && index !== work.images.length - 1) {
            setActiveImage(work.images[index + 1]);
          }
          return image.id === activeImage.id;
        });
      }
    };
    window.addEventListener("resize", resizeWindowHandler);
    window.addEventListener("keydown", arrowNavigateHandler);
    return () => {
      window.removeEventListener("resize", resizeWindowHandler);
      window.removeEventListener("keydown", arrowNavigateHandler);
    };
  }, [activeImage, work]);

  return (
    <Backdrop onClick={onClick} className={classes.backdrop}>
      <div className={classes["active-image"]}>
        <img
          onClick={(e) => {
            e.stopPropagation();
            setZoomedIn((prevState) => !prevState);
          }}
          className={zoomedIn && screenSize > 520 ? classes["zoom-in"] : ""}
          src={activeImage.src}
          alt={work.name}
        />
      </div>
      <div className={classes.navigator} onClick={(e) => e.stopPropagation()}>
        <div className={classes.swiper}>
          {work.images.map((image) => (
            <div
              key={image.id}
              className={`${classes.image} ${
                activeImage.id === image.id && classes.active
              }`}
              onClick={() => setActiveImage(image)}
            >
              <img src={image.src} alt="" />
            </div>
          ))}
        </div>
      </div>
    </Backdrop>
  );
};

export default memo(Navigator);
