import classes from "./ServiceItem.module.css";
const ServiceItem = (props) => {
  return (
    <div className={classes.service}>
      <img src={props.src} alt="" />
      <h1 className={classes.title}>{props.title}</h1>
      <p className={classes.description}>{props.description}</p>
    </div>
  );
};

export default ServiceItem;
