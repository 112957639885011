import ReactDOM from "react-dom";
import classes from "./Backdrop.module.css";
import { useEffect, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
const Backdrop = ({ children, className, onClick }) => {
  useEffect(() => {
    document.body.classList.add(classes["block-scrolling"]);
    return () => document.body.classList.remove(classes["block-scrolling"]);
  }, []);

  return ReactDOM.createPortal(
    <div onClick={onClick} className={`${classes.backdrop} ${className}`}>
      <button className={classes.close}>
        <FontAwesomeIcon icon={faClose} />
      </button>
      {children}
    </div>,
    document.getElementById("backdrop-root")
  );
};

export default memo(Backdrop);
