import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Footer.module.css";
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

const links = [
  {
    title: "Instagram",
    className: classes.instagram,
    icon: faInstagram,
    href: "https://www.instagram.com/valot.ads/?igshid=MzRlODBiNWFlZA",
  },
  {
    title: "Facebook",
    className: classes.facebook,
    icon: faFacebook,
    href: "https://www.facebook.com/profile.php?id=100089832766493&mibextid=ZbWKwL",
  },

  {
    title: "Tiktok",
    className: classes.tiktok,
    icon: faTiktok,
    href: "https://www.tiktok.com/@valot.adv?_t=8dwFb0lXUBy&_r=1",
  },

  {
    title: "Whatsapp",
    className: classes.whatsapp,
    icon: faWhatsapp,
    href: "https://wa.me/message/SWTQUXIRHY3NF1",
  },

  {
    title: "Twitter",
    className: classes.twitter,
    icon: faTwitter,
    href: "https://twitter.com/Valotads?t=6w0guqFcVthUAiqg4wNPNA&s=09",
  },
];

const Footer = (props) => {
  return (
    <footer>
      <div className={classes.body}>
        <div className={classes.us}>
          <span>
            <img src={require("../assets/logo.png")} alt="Valot" />
          </span>
          <p>
            where we specialize in helping businesses of all sizes achieve their
            marketing goals. Our team of experienced professionals is dedicated
            to providing top-notch services that are tailored to meet your
            specific needed.
          </p>
        </div>
        <div className={classes["contact-us"]}>
          <h2>Contact us</h2>
          <div className={classes.links}>
            {links.map((link) => (
              <a
                key={link.href}
                href={link.href}
                rel="noreferrer"
                target="_blank"
                className={link.className}
                title={link.title}
              >
                <FontAwesomeIcon icon={link.icon} />
              </a>
            ))}
          </div>
        </div>
        <div className={classes.payment}>
          <div>
            <img src={require("../assets/payment/visa.png")} alt="Visa" />
          </div>
          <div>
            <img
              src={require("../assets/payment/Mastercard.png")}
              alt="Mastercard"
            />
          </div>
          <div>
            <img src={require("../assets/payment/paypal.png")} alt="Paypal" />
          </div>
          <div>
            <img
              src={require("../assets/payment/vodafone.png")}
              alt="Vodafone Cash"
            />
          </div>
        </div>
      </div>
      <p className={classes.copyright}>
        Copyright &copy; {new Date().getFullYear()} <span>Valot</span>. All
        rights reserved.
      </p>
    </footer>
  );
};

export default Footer;
