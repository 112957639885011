import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Plan.module.css";

const Plan = ({ src, plan }) => {
  return (
    <div className={`${classes.plan} ${plan.best && classes["move-up"]}`}>
      {plan.best && <span className={classes.best}>Most Popular</span>}
      <div>
        <h2 className={classes.title}>{plan.title}</h2>
        {/* <img
          src={src}
          alt=""
        /> */}
      </div>
      <ul>
        {plan.features.map((feature, index) => (
          <li key={index}>
            {feature.valid ? (
              <FontAwesomeIcon className={classes.true} icon={faCheck} />
            ) : (
              <FontAwesomeIcon className={classes.false} icon={faClose} />
            )}
            <span>{feature.text}</span>
          </li>
        ))}
      </ul>
      <a
        className={classes.choose}
        target="_blank"
        rel="noreferrer"
        href="https://wa.me/message/SWTQUXIRHY3NF1"
      >
        Choose Plan
      </a>
    </div>
  );
};

export default Plan;
