import classes from "./MainTitle.module.css";

const MainTitle = (props) => {
  return (
    <h1 className={`${classes["main-title"]} ${props.className}`}>
      {props.children}
    </h1>
  );
};

export default MainTitle;
