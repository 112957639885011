import Plan from "./Plan";
import MainTitle from "./UI/MainTitle";
import classes from "./Plans.module.css";

const plans = [
  {
    title: "Silver",
    features: [
      { valid: true, text: "Marketing strategy" },
      { valid: true, text: "Media buying plan" },
      { valid: true, text: "(16-20) Monthly posts" },
      { valid: true, text: "(3) Daily stories" },
      { valid: false, text: "Videography" },
      { valid: false, text: "Photo shoots" },
      { valid: true, text: "Monthly reports" },
    ],
  },
  {
    title: "Platinum",
    best: true,
    features: [
      { valid: true, text: "Marketing strategy" },
      { valid: true, text: "Media buying plan" },
      { valid: true, text: "(22-26) Monthly posts" },
      { valid: true, text: "(3) Daily stories" },
      { valid: true, text: "20K Real followers" },
      { valid: true, text: "Photo shoots" },
      { valid: true, text: "Monthly reports" },
    ],
  },
  {
    title: "Gold",
    features: [
      { valid: true, text: "Marketing strategy" },
      { valid: true, text: "Media buying plan" },
      { valid: true, text: "(28) Monthly posts" },
      { valid: true, text: "(10) Daily stories" },
      { valid: true, text: "(2) Videography" },
      { valid: true, text: "Photo shoots" },
      { valid: true, text: "Monthly reports" },
    ],
  },
];

const Plans = () => {
  return (
    <section id="plans">
      <MainTitle className={classes.title}>Choose a Plan</MainTitle>
      <div className={classes.plans}>
        {plans.map((plan, index) => (
          <Plan key={index} plan={plan} />
        ))}
      </div>
    </section>
  );
};

export default Plans;
