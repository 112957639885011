import classes from "./Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from "react";
import { useCallback } from "react";
import Contacts from "./Contacts";

const links = [
  {
    text: "Home",
    href: "#home",
  },
  {
    text: "Services",
    href: "#services",
  },
  {
    text: "Clients",
    href: "#clients",
  },
  {
    text: "Portfolio",
    href: "#portfolio",
  },
  {
    text: "Plans",
    href: "#plans",
  },
  {
    text: "Offers",
    href: "#offers",
  },
];

const Header = () => {
  const [navIsShown, setNavIsShown] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const navRef = useRef();

  const closeNavHandler = useCallback(() => {
    if (navRef.current && screenSize <= 768 && navIsShown) {
      navRef.current.classList.add(classes["hide-nav"]);
      const timeout = setTimeout(() => {
        setNavIsShown(false);
      }, 200);
      return () => clearTimeout(timeout);
    }
  }, [navIsShown, screenSize]);

  const toggleMenuBarHandler = (e) => {
    e.stopPropagation();
    if (navIsShown) {
      closeNavHandler();
    } else setNavIsShown(true);
  };

  useEffect(() => {
    const resizeWindowHandler = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", resizeWindowHandler);
    return () => window.removeEventListener("resize", resizeWindowHandler);
  }, []);

  useEffect(() => {
    window.addEventListener("click", closeNavHandler);
    return () => window.removeEventListener("click", closeNavHandler);
  }, [closeNavHandler]);

  return (
    <header className={classes.header}>
      <div className={classes["main-header"]}>
        <a href="/">
          <span className={classes.logo}>
            <img src={require("../assets/logo.png")} alt="Valot" />
          </span>
        </a>
        <nav className={classes.nav}>
          {(navIsShown || screenSize > 768) && (
            <ul ref={navRef}>
              {links.map((li) => (
                <li key={li.href}>
                  <a href={li.href}>{li.text}</a>
                </li>
              ))}
            </ul>
          )}
          <FontAwesomeIcon
            onClick={toggleMenuBarHandler}
            icon={faBars}
            className={classes["menu-bar"]}
          />
        </nav>
      </div>
      <Contacts />
    </header>
  );
};

export default Header;
