import MainTitle from "./UI/MainTitle";
import classes from "./Services.module.css";
import ServiceItem from "./ServiceItem";

const services = [
  {
    src: require("../assets/services/1-Branding Identity.png"),
    title: "Branding Identity",
    description:
      "Branding Identity comprises various components: a distinctive logo, a harmonious color palette, carefully chosen typography, cohesive imagery, and a consistent design style. These elements work together to create a memorable and impactful brand presence.",
  },
  {
    src: require("../assets/services/5-social-media management.png"),
    title: "Social-media Management",
    description:
      "Social media management involves creating, scheduling, and analyzing content across various platforms to engage with audiences, build brand awareness, and drive meaningful interactions for businesses and individuals.",
  },
  {
    src: require("../assets/services/4-public-relations.png"),
    title: "Public Relations",
    description:
      "Public relations is a strategic communication practice aimed at managing the reputation and image of individuals, organizations, or brands. It involves crafting compelling narratives, fostering positive relationships, and effectively addressing public perception and crises.",
  },
  {
    src: require("../assets/services/2-Production.png"),
    title: "Production",
    description:
      "Producing captivating brand images and videos involves a meticulous process of planning, shooting, editing, and crafting visual narratives. The goal is to create compelling visuals that resonate with the target audience and effectively communicate the brand's message.",
  },
  {
    src: require("../assets/services/3-Events.png"),
    title: "Events",
    description:
      "Organizing brand events requires careful coordination of logistics, venue selection, guest management, and creative execution. From product launches to corporate conferences, these events aim to create memorable experiences that align with the brand's identity and engage attendees.",
  },
  {
    src: require("../assets/services/6-Website.png"),
    title: "Website",
    description:
      "Creating and developing websites for brands involves a blend of design expertise, user experience optimization, and technical implementation. It entails crafting visually appealing, functional sites that effectively convey the brand's message and drive online engagement.",
  },
];

const Services = (props) => {
  return (
    <section id="services">
      <MainTitle>Services</MainTitle>
      <div className={classes.services}>
        {services.map((service) => (
          <ServiceItem
            key={service.src}
            src={service.src}
            title={service.title}
            description={service.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Services;
