import classes from "./Background.module.css";
import ReactDOM from "react-dom";

const Background = () => {
  return ReactDOM.createPortal(
    <div className={classes.background}></div>,
    document.getElementById("background-root")
  );
};

export default Background;
